<template>
  <div>
    <h1>HELLO</h1>
  </div>
</template>

<script>
export default {
  name: 'Home',
  mounted() {
    console.log('Home component mounted')
  }
}
</script>